<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { defineProps, computed } from 'vue';
import * as solidIcons from '@heroicons/vue/20/solid';
import * as outlineIcons from '@heroicons/vue/24/outline';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    family: {
        type: String,
        default: 'solid',
        validator: (value) => ['solid', 'outline'].includes(value),
    },
});

const component = computed(() => {
    switch (props.family) {
        case 'outline':
            return outlineIcons[`${props.name}Icon`];
        default:
            return solidIcons[`${props.name}Icon`];
    }
});
</script>

<template>
    <component :is='component' class='w-7' />
</template>
