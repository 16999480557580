<template>
    <li>
        <router-link v-if='to' :to='to' class='sidenav-item' :class='{"active": activeRoute && isActive}'>
            <i v-if='icon' class='mr-2 fa' :class='`fa-${icon}`'></i>
            <span>{{ text }}</span>
        </router-link>
        <a
            v-else
            :href='externalLink || "#"'
            @click='doClick'
            target='_blank'
            class='sidenav-item'
            :class='{"active": active}'
        >
            {{ text }}
        </a>
    </li>
</template>

<script>
export default {
    name: 'SidenavItem',
    props: {
        to: {
            type: Object,
        },
        text: {
            type: String,
            required: true,
        },
        icon: String,
        activeRoute: String,
        externalLink: String,
        active: Boolean,
    },
    emits: ['click'],
    computed: {
        isActive () {
            return this.routeName === this.activeRoute;
        },
        routeName () {
            return {
                'fidu-client-new': 'client-new',
                'fidu-client-regular-transfer-list': 'client-transfer',
                'fidu-coda-search': 'coda',
                'fidu-soda-search': 'soda',
                'fidu-purchase-search': 'purchases',
                'fidu-services-root': 'services',
                'fidu-client-search': 'client',
                'fidu-info': 'info',
                'fidu-client-root': 'client',
                'fidu-client-uid': 'client',
                'fidu-help': 'help',
                'fidu-help-article': 'help',
                'fidu-purchase-service-onboard': 'services',
                'fidu-ccs-clients-list': 'ccs-clients-list',
            }[this.$route.name] || this.$route.name;
        },
    },
    methods: {
        doClick (e) {
            if (!this.externalLink) {
                e.preventDefault();
            }
            this.$emit('click', e);
        },
    },
};
</script>

<style scoped>
.sidenav-item {
    @apply inline-block py-2 px-4 w-full flex items-center text-grey-700;
}

.sidenav-item:active,
.sidenav-item:hover,
.sidenav-item:focus {
    @apply no-underline;
}
.sidenav-item:hover {
    @apply text-blue-500;
}

.active {
    @apply font-bold text-blue-500;
}

</style>
