<script>
    export default {
        name: 'SearchboxComponent',
        props: {
            list: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<template>
    <div class='cb-search-box' :class='{"not-empty":list.textsearch}'>
        <input
            class='form-control'
            type='text'
            v-model='list.textsearch'
            @submit.prevent.stop
            :placeholder='$t("lbl-search")'
        >
        <span class='cb-search-box-icon'>
            <i v-if='!list.reloading' class='fa fa-search'></i>
            <i v-else class='fa fa-spin fa-gear'></i>
        </span>
    </div>
</template>

<style lang='postcss' scoped>
.cb-search-box {
    @apply min-w-48 h-12;
  display: inline-block;
  position: relative;
}

input {
    @apply h-full;
}

.cb-search-box.not-empty .cb-search-box-icon {
  @apply text-blue-300;
  pointer-events: none;
}
.cb-search-box.not-empty .form-control {
  @apply text-blue-500 border-blue-500;
}
.cb-search-box-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
  right: 0;
  margin-right: 10px;
  width: 34px;
  text-align: center;
  color: #cccccc;
  font-size: 16px;
}
</style>
