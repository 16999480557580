<script setup>
import notify from '@/notify';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import {t} from '@/i18n';

const props = defineProps({
    url: {
        type: String,
        required: false,
    },
    labelLink: {
        type: String,
        default: 'lbl-start-digital-signing',
    },
    labelButton: {
        type: String,
        default: 'lbl-copy-digital-signing-link',
    },
});

async function copyToClipboard () {
    try {
        await navigator.clipboard.writeText(props.url);
        notify.success(t('p-link-copied-clipboard-success'));
    } catch (err) {
        notify.error(t('p-link-copied-clipboard-error'));
    }
}
</script>

<template>
    <div class='sign-button'>
        <a :href='url' target='_blank'>
            <FriendlyButton
                :label='labelLink'
                extra-small
                square
                micro
                no-margin
                no-background
                symbol='external-link'
            />
        </a>
        <FriendlyButton
            :label='labelButton'
            :action='copyToClipboard'
            square
            micro
            no-margin
            symbol='clipboard'
            class='ml-2'
        />
    </div>
</template>

<style lang='postcss' scoped>
.sign-button {
    @apply rounded-md ;
    display: flex;
    height: 30px;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
}
</style>
