<script setup>
import { ref, watch, defineProps } from 'vue';

const props = defineProps({
    steps: {
        type: Array,
        required: true,
        validator: (steps) => {
            // Check if every step is an object containing all the required properties
            const requiredProperties = ['key', 'title'];
            return steps.every(step =>
                step &&
                typeof step === 'object' &&
                requiredProperties.every(property => property in step),
            );
        },
    },
    step: {
        type: String,
    },
});

const emits = defineEmits(['update:step']);

// set temporary step (used internally) and listen to props changes
// defaults to the first step provided by steps
let currentStep = ref(props.step || props.steps[0].key);
watch(() => props.step, newStep => {
    if (newStep) {
        currentStep.value = newStep;
    }
});

function getCurrentStepIndex () {
    return props.steps.findIndex(step => step.key === currentStep.value);
}

function nextStep () {
    const nextStep = props.steps[getCurrentStepIndex() + 1];
    if (nextStep) {
        currentStep.value = nextStep.key;
        emits('update:step', currentStep.value);
    }
}

function previousStep () {
    const previousStep = props.steps[getCurrentStepIndex() - 1];
    if (previousStep) {
        currentStep.value = previousStep.key;
        emits('update:step', currentStep.value);
    }
}
</script>

<template>
    <div class='flex flex-row'>
        <div v-for='(st, index) in steps' class='relative w-full'>
            <!-- do not display the line on first element -->
            <hr
                v-if='index !== 0'
                class='line'
                :class='[currentStep >= st.key ? "bg-blue-500" : "bg-grey-250"]'
            >
            <div class='flex flex-col items-center relative'>
                <span class='absolute'>
                    <i class='fa fa-check block -mt-1 text-blue-500' v-if='currentStep > st.key'></i>
                    <span
                        v-else
                        class='block top-0 left-7 w-2 h-2 border-2 border-white dark:border-gray-800 rounded-full transition-colors'
                        :class='[currentStep >= st.key ? "bg-blue-500" : "bg-grey-250"]'
                    ></span>
                </span>
                <div
                    class='mt-5 font-bold text-sm'
                    :class='[currentStep === st.key ? "text-blue-500" : "text-grey-600"]'
                >
                    {{ st.title }}
                </div>
            </div>
        </div>
    </div>
    <div class='mt-10'>
        <slot
            :current-step='currentStep'
            :next-step='nextStep'
            :previous-step='previousStep'
        ></slot>
    </div>
</template>

<style scoped>
.line {
    @apply absolute h-1 m-0 border-0 rounded transition-colors;
    margin-top: 2px;
    left: calc(-50% + 16px);
    right: calc(50% + 16px);
}
</style>
