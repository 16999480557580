<script>
    import IbanInput from './IbanInput.vue';
    import { gql } from '@apollo/client/core';
    import FormCheckbox from '@/components/FormCheckbox';
    import FriendlyButton from '@/clientcomponents/FriendlyButton';
    import { FieldArray } from 'vee-validate';
    import UniqueBankAccountNotice from '@/components/UniqueBankAccountNotice.vue';

    export default {
        name: 'ClientAddCoda',
        props: {
            clientV2: {
                type: Object,
            },
            formRef: Object,
            hideExtras: Boolean,
        },
        components: {
            UniqueBankAccountNotice,
            FriendlyButton,
            FormCheckbox,
            IbanInput,
            FieldArray,
        },
        data () {
            return {
                id: 0,
                orderableBankAccounts: [],
                notOrderableBankAccounts: [],
            };
        },
        async mounted () {
            await this.getExistingBankAccount();
            this.initInput();
        },
        emits: ['input'],
        methods: {
            initInput () {
                if (this.orderableBankAccounts.length === 0) {
                    this.$refs.newBankAccountsFieldArray.push();
                }
            },
            isIbanDuplicated (allBankAccounts, bankAccount) {
                return bankAccount.length > 0 && // we do not want to show the message in multiple empty ibans
                       allBankAccounts.filter(i => i.replace(/\s+/g, '') === bankAccount.replace(/\s+/g, '')).length > 1;
            },
            async getExistingBankAccount () {
                if (this.clientV2) {
                    const { data } = await this.$apollo.query({
                        query: gql`query getBankAccounts($clientId: String) {
                            bankAccounts(clientId: $clientId) {
                                id
                                bankId
                                bankMandateState
                                type
                                state
                                iban
                            }
                        }`,
                        variables: {
                            clientId: this.clientV2.id,
                        },
                    });
                    for (const bankAccount of data.bankAccounts) {
                        if (bankAccount.bankMandateState === 'stop-coda-requested') {
                            continue;
                        }
                        const bank = this.$store.state.allBanks.find((bank) => bank.id === bankAccount.bankId);
                        bankAccount.bank = bank;
                        if (bankAccount.state === 'archived' && bank && bank.isSupported) {
                            this.orderableBankAccounts.push(bankAccount);
                        } else {
                            this.notOrderableBankAccounts.push(bankAccount);
                        }
                    }
                }
            },
            async validateBankAccounts () {
                let hasErrors = false;

                // Validate new BAs provided via text inputs
                const newBankAccounts = this.formRef.values.newBankAccounts;
                const existingBankAccounts = this.formRef.values.existingBankAccounts;
                if (newBankAccounts) {
                    // Build one list with all BA to order from text input (new BAs) and checkboxes (existing BAs) for the duplicate validation
                    let bankAccountsToOrder = [];

                    if (newBankAccounts && newBankAccounts.length > 0) {
                        newBankAccounts.forEach(ba => {
                            if (ba) {
                                bankAccountsToOrder.push(ba);
                            }
                        });
                    }

                    if (existingBankAccounts && existingBankAccounts.length > 0) {
                        existingBankAccounts.forEach(ba => {
                            if (ba) {
                                bankAccountsToOrder.push(ba);
                            }
                        });
                    }

                    // Loop on text inputs (new BAs) to check if the value is duplicate and set errors on input
                    for (let i = 0; i < newBankAccounts.length; i++) {
                        const newBa = newBankAccounts[i];

                        if (newBa) {
                            const fieldName = `newBankAccounts[${i}]`;

                            const isDuplicated = this.isIbanDuplicated(bankAccountsToOrder, newBa);

                            if (isDuplicated) {
                                hasErrors = true;

                                // Display error for the input texts
                                this.formRef.setErrors({
                                    [fieldName]: 'err-val-unique-acc',
                                });

                                // Also display error on checkbox if a selected checkbox have same value
                                if (existingBankAccounts && existingBankAccounts.length > 0) {
                                    const checkboxIndex = existingBankAccounts.findIndex(existingBa => existingBa && existingBa.replace(/\s+/g, '') === newBa.replace(/\s+/g, ''));
                                    if (checkboxIndex !== -1) {
                                        this.formRef.setErrors({
                                            [`existingBankAccounts[${checkboxIndex}]`]: 'err-val-unique-acc',
                                        });
                                    }
                                }
                            } else {
                                // BA validation on input based on ref that are the same as name of the field
                                const validIban = await this.$refs[fieldName][0].validate();
                                if (!validIban) {
                                    hasErrors = true;
                                }
                            }
                        }
                    }
                }

                return !hasErrors;
            },
            resetValidation (errorIndex) {
                this.formRef.setErrors({
                    [errorIndex]: null,
                });
            },
        },
    };
</script>
<template>
    <section>
        <div class='mb-6'>
            <FieldArray name='newBankAccounts' v-slot='{ fields, push, remove }' ref='newBankAccountsFieldArray'>
                <div v-for='(field, i) in fields' :key='field.key'>
                    <div class='flex items-start mb-3'>
                        <IbanInput
                            :name='`newBankAccounts[${i}]`'
                            :ref='`newBankAccounts[${i}]`'
                            :id='`new_bank_accounts_${i}`'
                            :form-ref='formRef'
                            :rules='`${formRef.values.newBankAccounts && formRef.values.newBankAccounts.length > 0 ? "required" : ""}`'
                            no-label
                            narrow
                        />
                        <FriendlyButton
                            v-if='formRef.values.newBankAccounts && formRef.values.newBankAccounts.length > 0'
                            symbol='times'
                            @click='remove(i)'
                            micro
                            secondary
                            square
                            no-text
                            no-margin
                            class='ml-2 mt-2'
                            no-border
                        />
                    </div>
                    <UniqueBankAccountNotice
                        :id-suffix='`${i}`'
                        :error-code='formRef.errors[`newBankAccounts[${i}]`]'
                        :iban='field["value"]'
                        @reset='() => resetValidation(`newBankAccounts[${i}]`)'
                    />
                </div>
                <FriendlyButton
                    symbol='plus'
                    @click='push()'
                    id='addBankAccountButton'
                    extra-small
                    square
                    label='btn-add-bank-account'
                    no-margin
                    small-icon
                />
            </FieldArray>

            <template v-if='!hideExtras'>
                <span class='block w-full h-px bg-gray-200 mb-6 mt-6' v-if='orderableBankAccounts.length > 0'></span>

                <div class='mb-6 orderable-bank-accounts' v-if='orderableBankAccounts.length > 0 '>
                    <h4>{{ $t('ttl-orderable-bank-accounts', {clientName: clientV2.enterpriseName}) }}</h4>

                    <p>
                        {{ $t('txt-orderable-bank-accounts') }}
                    </p>

                    <div
                        :class='`${orderableBankAccount.error ? "has-error" : ""}`'
                        v-for='(orderableBankAccount, i) in orderableBankAccounts'
                        :key='i'
                    >
                        <FormCheckbox
                            :label='`${orderableBankAccount.iban} (${orderableBankAccount.bank.name})`'
                            :name='`existingBankAccounts[${i}]`'
                            :checked-value='orderableBankAccount.iban'
                            edit
                        />
                    </div>
                </div>

                <span class='block w-full h-px bg-gray-200 mb-6 mt-6' v-if='notOrderableBankAccounts.length'></span>

                <div class='not-orderable-bank-accounts' v-if='notOrderableBankAccounts.length'>
                    <h4>{{ $t('ttl-not-orderable-bank-accounts', {clientName: clientV2.enterpriseName}) }}</h4>

                    <p>
                        {{ $t('txt-not-orderable-bank-accounts') }}
                    </p>

                    <ul class='list-unstyled'>
                        <li v-for='(notOrderableBankAccount, index) in notOrderableBankAccounts' :key='index'>
                            {{ notOrderableBankAccount.iban }} ({{ notOrderableBankAccount.bank.name }})
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </section>
</template>

<style lang='scss' scoped>
    .has-error, .has-error.checkbox label {
        color: $error-color;
    }
</style>
