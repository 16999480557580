<script setup>
import {computed, onMounted, ref} from 'vue';
import CodaDownload from '@/components/Codadownload.vue';
import ReinviteTwikeyButton from '@/components/ReinviteTwikeyButton.vue';
import DigitalSignatureButton from '@/components/DigitalSignatureButton.vue';
import Tooltip from '@/components/Tooltip.vue';

const props = defineProps({
    mandate: {
        type: Object,
        required: true,
    },
    retrySignhereUrlTimeout: {
        type: Number,
        default: 3000,
    },
    retrySignhereUrlAttempts: {
        type: Number,
        default: 10,
    },
});

const emits = defineEmits(['refreshCodaMandates']);

const signhereUrlCreationCounter = ref(0);

const canHaveButtons = computed(() => ['available-online', 'prepared', 'sent-client', 'problem'].includes(props.mandate.state));

const isPaperSignatureNeeded = computed(() => !props.mandate.bank.isTwikeySupported && !props.mandate.bank.isSignhereSupported && !isTTMonly(props.mandate));
const needsTwikeyReinvite = computed(() => props.mandate.bank.isTwikeySupported && props.mandate.twikeyUrl && isTwikeyUrlExpired(props.mandate));
const isSignHerePackageBeingCreated = computed(() => (props.mandate.bank.isSignhereSupported || isTTMonly(props.mandate)) && !props.mandate.signHerePackage?.packageSignUrl);
const stoppedWaitingForSignhereToBeReady = computed(() => signhereUrlCreationCounter.value > props.retrySignhereUrlAttempts - 1);

const digitalSignatureURL = computed(() => props.mandate.twikeyUrl ? props.mandate.twikeyUrl : props.mandate.signHerePackage.packageSignUrl);

function isTwikeyUrlExpired (mandate) {
    const currentDate = new Date();
    const cutoff = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
    return new Date(mandate.twikeyUrlCreatedAt) < cutoff;
}

function isTTMonly (mandate) {
    return mandate.bankAccounts.filter((ba) => ba.state !== 'archived').every((ba) => ba.flowState === 'draft-active-bank-mandate');
}

function retrySignhereUrl () {
    if (isSignHerePackageBeingCreated.value && !stoppedWaitingForSignhereToBeReady.value) {
        setTimeout(() => {
            emits('refreshCodaMandates');
            signhereUrlCreationCounter.value++;
            retrySignhereUrl();
        }, props.retrySignhereUrlTimeout);
    }
}

function onReinvited () {
    emits('refreshCodaMandates');
}

onMounted(retrySignhereUrl);
</script>

<template>
    <div v-if='canHaveButtons'>
        <template v-if='isPaperSignatureNeeded'>
            <CodaDownload :mandate='mandate' />
        </template>
        <template v-else-if='isSignHerePackageBeingCreated'>
            <template v-if='stoppedWaitingForSignhereToBeReady'>
                <!--
                    The bff only returns signhere packages that are in state pending or rejected.
                    This means we don't know whether a new package is being created or not, so if
                    we don't get a signhere package by the end of the waiting loop, we assume that
                    a mandate in state problem is not yet fixed.
                    Note that even when the mandate is fixed, the mandate stays in problem.
                -->
                <span v-if='mandate.state === "problem"' class='text-red-300'>
                    {{ $t('lbl-digital-signature-issue-problem') }}
                </span>
                <tooltip v-else left class='text-red-300' data-test='signature-unavailable-tooltip'>
                    <template #trigger>
                        <i class='fa fa-warning'></i>
                        {{ $t('lbl-digital-signature-not-available') }}
                    </template>
                    <template #content>
                        {{ $t('lbl-digital-signature-issue') }}
                    </template>
                </tooltip>
            </template>
            <template v-else>
                {{ $t('lbl-wait-digital-signature-url') }}... <i class='fa fa-circle-o-notch fa-spin'></i>
            </template>
        </template>
        <template v-else>
            <div class='flex'>
                <DigitalSignatureButton :url='digitalSignatureURL' />
                <template v-if='needsTwikeyReinvite'>
                    <ReinviteTwikeyButton
                        :mandate='mandate'
                        @reinvited='onReinvited'
                        data-test='reinvite-twikey-button'
                    />
                </template>
            </div>
        </template>
    </div>
</template>
